@mixin active() {
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @content;
  }
}

@mixin focus() {
  &:focus,
  &.focus {
    @content;
  }
}

@mixin focus-active() {
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:focus,
  &.focus {
    @content;
  }
}

@mixin custom-control-size($multiplier) {
  .custom-control {
    min-height: 1.6rem * $multiplier;
    padding-left: 1rem * ($multiplier * 1.35);
  }

  .custom-control-input {
    width: 1rem * $multiplier;
    height: 1.3rem * $multiplier;
  }

  .custom-control-label {
    font-size: 1rem * $multiplier;
  } 

  .custom-control-label::before, 
  .custom-control-label::after {
      top: 0.3rem * $multiplier;
      left: -1.5rem * ($multiplier * 0.85);
      width: 1rem * $multiplier;
      height: 1rem * $multiplier;
  }
}

@import './theme/global.scss';
@import './theme/utilities.scss';
@import './theme/variables.scss';
@import './theme/fonts.scss';

@import '~bootstrap/scss/bootstrap.scss';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import '~rc-slider/assets/index.css';

@import './theme/extensions.scss';

@import './theme/notification.scss'

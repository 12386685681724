.notification-container {
  .notification {
    background: #FFF;
  }

  .notification-info {
    border: 1px solid var(--primary);
    color: var(--primary);
    .title {
      color: var(--primary);
    }
  }

  .notification-success {
    border: 1px solid green;
    color: green;
    .title {
      color: green;
    }
  }

  .notification-warning {
    border: 1px solid orange;
    color: orange;
    .title {
      color: orange;
    }
  }

  .notification-error {
    border: 1px solid red;
    color: red;
    .title {
      color: red;
    }
  }
}

.client-thoughts-carousel {
  padding-bottom: 44px;
}

.client-thoughts-carousel .carousel-control-prev,
.client-thoughts-carousel .carousel-control-next {
  width: 6%;
}


.client-thoughts-carousel .carousel-control-prev-icon,
.client-thoughts-carousel .carousel-control-next-icon {
  width: 48px;
  height: 12px;
}

.client-thoughts-carousel .carousel-control-prev-icon {
  background-image: url(./images/carousel-prev-icon.svg);
}

.client-thoughts-carousel .carousel-control-next-icon {
  background-image: url(./images/carousel-next-icon.svg);
}

.client-thoughts-carousel .carousel-indicators {
  margin-bottom: 0;
}

.client-thoughts-carousel .carousel-indicators li {
  width: 12px;
  height: 12px;
  background-color: var(--light);
}

.client-thoughts-carousel .carousel-indicators li.active {
  width: 20px;
  height: 20px;
  background-color: var(--primary);
}

// == UI Kit ==

$profyle-color-main-lighter: #FFD56B;
$profyle-color-main: #FFBE17;
$profyle-color-main-darker: #F4B510;
$profyle-color-main-darkest: #EBA900;
$profyle-color-black: #06061C;
$profyle-color-gray-for-text: #65697E;
$profyle-color-prompt: #65697E;
$profyle-color-gray: #D4D6DB;
$profyle-color-light-gray: #F0F0F1;
$profyle-color-input-bg: #F7F7F8;
$profyle-color-bg: #FAFAFA;

$profyle-font-weight-light: 300;
$profyle-font-weight-extra-bold: 800;

$gray-300: #dee2e6;

// UI mockups

$profyle-color-white: #FFF;
$profyle-color-additional: $profyle-color-white;
$profyle-color-danger: #FF7373;



// == Bootstrap ==


// Info
// 1rem = 16px


// Basics

$theme-colors: (
    "primary":    $profyle-color-main,
    "secondary":  $profyle-color-additional,
    // "success":    $success,
    // "info":       $info,
    // "warning":    $warning,
    "danger":     $profyle-color-danger,
    "light":      $profyle-color-light-gray,
    "dark":       $profyle-color-gray-for-text,
    "gray":       $profyle-color-gray,
);

$body-color: $profyle-color-gray-for-text;
$spacer: 1rem;

$profyle-font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-sans-serif: Muli, $profyle-font-family-base;
$line-height-base: 1.6;

$border-radius: 0;
$border-radius-lg: $border-radius;
$border-radius-sm: $border-radius;


// Headings

$h1-font-size: 3rem;
$h2-font-size: 2.25rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1.125rem;
$h6-font-size: 1rem;
$headings-color: $profyle-color-black;
$headings-font-weight: $profyle-font-weight-extra-bold;
$headings-font-family: Gilroy, $profyle-font-family-base;


// Buttons

$btn-padding-y: 12px;
$btn-padding-x: 24px;
$btn-font-weight: $profyle-font-weight-extra-bold;
$btn-font-family: Gilroy, $profyle-font-family-base;


// Inputs

// $input-font-weight: bold;
$input-bg: $profyle-color-input-bg;
$input-color: $profyle-color-black;
$input-border-color: $profyle-color-input-bg;
$input-disabled-bg: $profyle-color-light-gray;
$input-placeholder-color: $profyle-color-prompt;
$input-plaintext-color: $profyle-color-black;

// Variables

:root {
  --profyle-profyle-color-main-lighter: #{$profyle-color-main-lighter};
  --profyle-profyle-color-main: #{$profyle-color-main};
  --profyle-profyle-color-bg: #{$profyle-color-bg};
  --profyle-profyle-color-input-bg: #{$profyle-color-input-bg};
  --profyle-profyle-color-black: #{$profyle-color-black};

  --profyle-navbar-height: 44px;

  --profyle-muli-font-family: #{$font-family-sans-serif};
  --profyle-headings-font-family: #{$headings-font-family};
  --profyle-headings-font-weight: #{$headings-font-weight};
  --profyle-headings-color: #{$headings-color};
  --profyle-h1-font-size: #{$h1-font-size};
  --profyle-h2-font-size: #{$h2-font-size};
  --profyle-h3-font-size: #{$h3-font-size};
  --profyle-h4-font-size: #{$h4-font-size};
  --profyle-h5-font-size: #{$h5-font-size};

  --profyle-gray-300: #{$gray-300};
  --profyle-color-gray-for-text: #{$profyle-color-gray-for-text};

  --profyle-line-height-base: #{$line-height-base};
}


// Headings

h1, h2,
.h1, .h2 {
  text-align: center;
  margin-bottom: $spacer * 3;
}


// Buttons

.btn {
  &-primary {
    color: $profyle-color-white;

    @include hover() {
      background-color: $profyle-color-main-darker;
      color: $profyle-color-white;
    }

    @include focus-active() {
      background-color: $profyle-color-main-darkest;
      color: $profyle-color-white;
    }
  }

  &-secondary {
    border-color: $profyle-color-gray;

    @include hover() {
      border-color: $profyle-color-prompt;
    }

    @include focus-active() {
      border-color: $profyle-color-gray-for-text;
    }
  }
}

// Sizes

.w-50-resp {
  width: 100%;
}
@include media-breakpoint-up(lg) { 
  .w-50-resp {
    width: 50%;
  }
}

.m-5-resp {
  margin: 1rem !important;
}
@include media-breakpoint-up(lg) { 
  .m-5-resp {
    margin: 3rem !important;
  }
}


// Forms

.form {
  &-label {
    color: $profyle-color-gray-for-text;
  }
}

select {
  option {
    &:checked,
    &.checked {
      background: $profyle-color-main-lighter;
    }
  }
}

// Disable autocomplete highlight for plaintext controls
input.form-control-plaintext:-webkit-autofill,
input.form-control-plaintext:-webkit-autofill:hover, 
input.form-control-plaintext:-webkit-autofill:focus, 
input.form-control-plaintext:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: $input-plaintext-color !important;
}

input.form-control-plaintext  {
  outline: 0;
  border: 0;
  cursor: default;

  // @TODO: Find a bootstrap variable name for it
  &[readonly]::placeholder{
    color: $profyle-color-prompt;
    // Fix for Firefox
    opacity: 1;
  }
}

.custom-select:invalid {
  color: $input-placeholder-color;
}

.form-control-plaintext,
.custom-plaintext {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
}

.custom-plaintext {
  display: block;
  width: 100%;
  padding: $input-padding-y 0;
  margin-bottom: 0; // match inputs if this class comes on inputs with default margins
  @include font-size($input-font-size);
  line-height: $input-line-height;
  background-color: transparent;
  border: solid transparent;
  border-width: $input-border-width 0;
  color: $input-plaintext-color;

  &.custom-plaintext-sm,
  &.custom-plaintext-lg {
    padding-right: 0;
    padding-left: 0;
  }
}

[disabled].custom-plaintext {
  opacity: 1;
}

select.custom-plaintext {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// Navbar

.navbar-nav .btn {
  $padding-y: 0.5rem;
  padding-top: $padding-y;
  padding-bottom: $padding-y;
}
@include media-breakpoint-up(lg) { 
  .navbar-nav .btn {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

// Editor
.rdw-editor-wrapper {
  border: 1px solid #F1F1F1;
}


// Emphasis
.emphasis {
  font-weight: inherit;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 0.15em;
  text-decoration-color: var(--primary);
}
